import type {
  FetchAppointmentsPayload,
  FetchAppointmentsResponseData,
  FetchAppointmentResponseData,
  UpdateDraftAppointmentPayload,
  CreateDraftAppointmentPayload,
  SavePublishedAppointmentPayload,
  CancelAppointmentPayload,
  DeleteAppointmentPayload,
  FetchAppointmentPayload,
  AssignManualAppointmentPayload,
  AssignRescheduledBeautyCenterAppointmentPayload,
  AssignRescheduledCustomerAppointmentPayload,
  UpdateAppointmentPayload,
  MarkAbsenceAppointmentPayload,
} from "@/utils/apis/appointment";

export enum AppointmentActionTypes {
  FETCH_REQUESTED = "@@appointment/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointment/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointment/FETCH_FAILED",

  FETCH_APPOINTMENT_SUCCEEDED_SERVER = "@@appointment/FETCH_APPOINTMENT_SUCCEEDED_SERVER",

  // Saga
  FETCH_APPOINTMENT_SAGA = "@@appointment/FETCH_APPOINTMENT_SAGA",
  FETCH_APPOINTMENTS_SAGA = "@@appointment/FETCH_APPOINTMENTS_SAGA",
  FETCH_DRAFT_APPOINTMENTS_SAGA = "@@appointment/FETCH_DRAFT_APPOINTMENTS_SAGA",
  FETCH_PROCESSING_ASSIGN_APPOINTMENTS_SAGA = "@@appointment/FETCH_PROCESSING_ASSIGN_APPOINTMENTS_SAGA",
  FETCH_RESCHEDULED_APPOINTMENTS_SAGA = "@@appointment/FETCH_RESCHEDULED_APPOINTMENTS_SAGA",
  FETCH_ACCEPTED_APPOINTMENTS_SAGA = "@@appointment/FETCH_ACCEPTED_APPOINTMENTS_SAGA",
  FETCH_CHECKED_IN_APPOINTMENTS_SAGA = "@@appointment/FETCH_CHECKED_IN_APPOINTMENTS_SAGA",
  FETCH_ABSENCE_APPOINTMENTS_SAGA = "@@appointment/FETCH_ABSENCE_APPOINTMENTS_SAGA",
  FETCH_IN_COMPLETED_APPOINTMENTS_SAGA = "@@appointment/FETCH_IN_COMPLETED_APPOINTMENTS_SAGA",
  FETCH_CANCELLED_APPOINTMENTS_SAGA = "@@appointment/FETCH_CANCELLED_APPOINTMENTS_SAGA",

  ASSIGN_MANUAL_APPOINTMENT_SAGA = "@@appointment/ASSIGN_MANUAL_APPOINTMENT_SAGA",
  ASSIGN_RESCHEDULED_BEAUTY_CENTER_APPOINTMENT_SAGA = "@@appointment/ASSIGN_RESCHEDULED_BEAUTY_CENTER_APPOINTMENT_SAGA",
  ASSIGN_RESCHEDULED_CUSTOMER_APPOINTMENT_SAGA = "@@appointment/ASSIGN_RESCHEDULED_CUSTOMER_APPOINTMENT_SAGA",
  UPDATE_DRAFT_APPOINTMENT_SAGA = "@@appointment/UPDATE_DRAFT_APPOINTMENT_SAGA",
  CREATE_DRAFT_APPOINTMENT_SAGA = "@@appointment/CREATE_DRAFT_APPOINTMENT_SAGA",
  SAVE_PUBLISHED_APPOINTMENT_SAGA = "@@appointment/SAVE_PUBLISHED_APPOINTMENT_SAGA",
  CANCEL_APPOINTMENT_SAGA = "@@appointment/CANCEL_APPOINTMENT_SAGA",
  DELETE_APPOINTMENT_SAGA = "@@appointment/DELETE_APPOINTMENT_SAGA",
  UPDATE_APPOINTMENT_SAGA = "@@appointment/UPDATE_APPOINTMENT_SAGA",
  MARK_ABSENCE_APPOINTMENT_SAGA = "@@appointment/MARK_ABSENCE_APPOINTMENT_SAGA",
}

// State

export interface AppointmentState {
  hydrated?: boolean;

  draftAppointments: FetchAppointmentsResponseData["data"];
  draftAppointmentsLoading: boolean;
  draftAppointmentsError: string;
  draftAppointmentsCount: number;

  processingAssignAppointments: FetchAppointmentsResponseData["data"];
  processingAssignAppointmentsLoading: boolean;
  processingAssignAppointmentsError: string;
  processingAssignAppointmentsCount: number;

  rescheduledAppointments: FetchAppointmentsResponseData["data"];
  rescheduledAppointmentsLoading: boolean;
  rescheduledAppointmentsError: string;
  rescheduledAppointmentsCount: number;

  acceptedAppointments: FetchAppointmentsResponseData["data"];
  acceptedAppointmentsLoading: boolean;
  acceptedAppointmentsError: string;
  acceptedAppointmentsCount: number;

  checkedInAppointments: FetchAppointmentsResponseData["data"];
  checkedInAppointmentsLoading: boolean;
  checkedInAppointmentsError: string;
  checkedInAppointmentsCount: number;

  absenceAppointments: FetchAppointmentsResponseData["data"];
  absenceAppointmentsLoading: boolean;
  absenceAppointmentsError: string;
  absenceAppointmentsCount: number;

  inCompletedAppointments: FetchAppointmentsResponseData["data"];
  inCompletedAppointmentsLoading: boolean;
  inCompletedAppointmentsError: string;
  inCompletedAppointmentsCount: number;

  cancelledAppointments: FetchAppointmentsResponseData["data"];
  cancelledAppointmentsLoading: boolean;
  cancelledAppointmentsError: string;
  cancelledAppointmentsCount: number;

  appointment: FetchAppointmentResponseData | null;
  appointmentLoading: boolean;
  appointmentError: string;

  appointments: FetchAppointmentsResponseData["data"];
  appointmentsLoading: boolean;
  appointmentsError: string;
  appointmentsCount: number;
}

// ---- Reducer Action ----

export type FetchScope =
  | "draftAppointments"
  | "processingAssignAppointments"
  | "rescheduledAppointments"
  | "acceptedAppointments"
  | "checkedInAppointments"
  | "absenceAppointments"
  | "inCompletedAppointments"
  | "cancelledAppointments"
  | "appointment"
  | "appointments";

export type FetchRequestedAction = {
  type: AppointmentActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchAppointmentSucceededServerAction = {
  type: AppointmentActionTypes.FETCH_APPOINTMENT_SUCCEEDED_SERVER;
  payload: FetchAppointmentResponseData | null;
};

// ---- Saga Action ----

export type FetchAppointmentSagaAction = {
  type: AppointmentActionTypes.FETCH_APPOINTMENT_SAGA;
  payload: FetchAppointmentPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchDraftAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_DRAFT_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchProcessingAssignAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_PROCESSING_ASSIGN_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchRescheduledAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_RESCHEDULED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAcceptedAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchCheckedInAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_CHECKED_IN_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAbsenceAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_ABSENCE_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchInCompletedAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_IN_COMPLETED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchCancelledAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_CANCELLED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateDraftAppointmentSagaAction = {
  type: AppointmentActionTypes.UPDATE_DRAFT_APPOINTMENT_SAGA;
  payload: UpdateDraftAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateDraftAppointmentSagaAction = {
  type: AppointmentActionTypes.CREATE_DRAFT_APPOINTMENT_SAGA;
  payload: CreateDraftAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SavePublishedAppointmentSagaAction = {
  type: AppointmentActionTypes.SAVE_PUBLISHED_APPOINTMENT_SAGA;
  payload: SavePublishedAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CancelAppointmentSagaAction = {
  type: AppointmentActionTypes.CANCEL_APPOINTMENT_SAGA;
  payload: CancelAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteAppointmentSagaAction = {
  type: AppointmentActionTypes.DELETE_APPOINTMENT_SAGA;
  payload: DeleteAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type AssignManualAppointmentSagaAction = {
  type: AppointmentActionTypes.ASSIGN_MANUAL_APPOINTMENT_SAGA;
  payload: AssignManualAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type AssignRescheduledBeautyCenterAppointmentSagaAction = {
  type: AppointmentActionTypes.ASSIGN_RESCHEDULED_BEAUTY_CENTER_APPOINTMENT_SAGA;
  payload: AssignRescheduledBeautyCenterAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type AssignRescheduledCustomerAppointmentSagaAction = {
  type: AppointmentActionTypes.ASSIGN_RESCHEDULED_CUSTOMER_APPOINTMENT_SAGA;
  payload: AssignRescheduledCustomerAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateAppointmentSagaAction = {
  type: AppointmentActionTypes.UPDATE_APPOINTMENT_SAGA;
  payload: UpdateAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type MarkAbsenceAppointmentSagaAction = {
  type: AppointmentActionTypes.MARK_ABSENCE_APPOINTMENT_SAGA;
  payload: MarkAbsenceAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchAppointmentSucceededServerAction
  //
  | FetchAppointmentSagaAction
  | FetchAppointmentsSagaAction
  | FetchDraftAppointmentsSagaAction
  | FetchProcessingAssignAppointmentsSagaAction
  | FetchRescheduledAppointmentsSagaAction
  | FetchAcceptedAppointmentsSagaAction
  | FetchCheckedInAppointmentsSagaAction
  | FetchAbsenceAppointmentsSagaAction
  | FetchInCompletedAppointmentsSagaAction
  | FetchCancelledAppointmentsSagaAction
  | UpdateDraftAppointmentSagaAction
  | CreateDraftAppointmentSagaAction
  | SavePublishedAppointmentSagaAction
  | CancelAppointmentSagaAction
  | DeleteAppointmentSagaAction
  | AssignManualAppointmentSagaAction
  | AssignRescheduledBeautyCenterAppointmentSagaAction
  | AssignRescheduledCustomerAppointmentSagaAction
  | UpdateAppointmentSagaAction
  | MarkAbsenceAppointmentSagaAction;
