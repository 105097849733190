import { AppointmentActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchAppointmentSucceededServerAction,
  // Saga
  FetchAppointmentSagaAction,
  FetchAppointmentsSagaAction,
  FetchDraftAppointmentsSagaAction,
  FetchProcessingAssignAppointmentsSagaAction,
  FetchRescheduledAppointmentsSagaAction,
  FetchAcceptedAppointmentsSagaAction,
  FetchCheckedInAppointmentsSagaAction,
  FetchAbsenceAppointmentsSagaAction,
  FetchInCompletedAppointmentsSagaAction,
  FetchCancelledAppointmentsSagaAction,
  CreateDraftAppointmentSagaAction,
  SavePublishedAppointmentSagaAction,
  UpdateDraftAppointmentSagaAction,
  CancelAppointmentSagaAction,
  DeleteAppointmentSagaAction,
  AssignManualAppointmentSagaAction,
  AssignRescheduledBeautyCenterAppointmentSagaAction,
  AssignRescheduledCustomerAppointmentSagaAction,
  UpdateAppointmentSagaAction,
  MarkAbsenceAppointmentSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AppointmentActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AppointmentActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AppointmentActionTypes.FETCH_FAILED,
  payload,
});

export const fetchAppointmentSucceededServer = (
  payload: FetchAppointmentSucceededServerAction["payload"]
): FetchAppointmentSucceededServerAction => ({
  type: AppointmentActionTypes.FETCH_APPOINTMENT_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchAppointmentSaga = (
  payload: FetchAppointmentSagaAction["payload"],
  meta?: FetchAppointmentSagaAction["meta"]
): FetchAppointmentSagaAction => ({
  type: AppointmentActionTypes.FETCH_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const fetchAppointmentsSaga = (
  payload?: FetchAppointmentsSagaAction["payload"],
  meta?: FetchAppointmentsSagaAction["meta"]
): FetchAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchDraftAppointmentsSaga = (
  payload?: FetchDraftAppointmentsSagaAction["payload"],
  meta?: FetchDraftAppointmentsSagaAction["meta"]
): FetchDraftAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_DRAFT_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchProcessingAssignAppointmentsSaga = (
  payload?: FetchProcessingAssignAppointmentsSagaAction["payload"],
  meta?: FetchProcessingAssignAppointmentsSagaAction["meta"]
): FetchProcessingAssignAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_PROCESSING_ASSIGN_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchRescheduledAppointmentsSaga = (
  payload?: FetchRescheduledAppointmentsSagaAction["payload"],
  meta?: FetchRescheduledAppointmentsSagaAction["meta"]
): FetchRescheduledAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_RESCHEDULED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchAcceptedAppointmentsSaga = (
  payload?: FetchAcceptedAppointmentsSagaAction["payload"],
  meta?: FetchAcceptedAppointmentsSagaAction["meta"]
): FetchAcceptedAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchCheckedInAppointmentsSaga = (
  payload?: FetchCheckedInAppointmentsSagaAction["payload"],
  meta?: FetchCheckedInAppointmentsSagaAction["meta"]
): FetchCheckedInAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_CHECKED_IN_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchAbsenceAppointmentsSaga = (
  payload?: FetchAbsenceAppointmentsSagaAction["payload"],
  meta?: FetchAbsenceAppointmentsSagaAction["meta"]
): FetchAbsenceAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_ABSENCE_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchInCompletedAppointmentsSaga = (
  payload?: FetchInCompletedAppointmentsSagaAction["payload"],
  meta?: FetchInCompletedAppointmentsSagaAction["meta"]
): FetchInCompletedAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_IN_COMPLETED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchCancelledAppointmentsSaga = (
  payload?: FetchCancelledAppointmentsSagaAction["payload"],
  meta?: FetchCancelledAppointmentsSagaAction["meta"]
): FetchCancelledAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_CANCELLED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const updateDraftAppointmentSaga = (
  payload: UpdateDraftAppointmentSagaAction["payload"],
  meta?: UpdateDraftAppointmentSagaAction["meta"]
): UpdateDraftAppointmentSagaAction => ({
  type: AppointmentActionTypes.UPDATE_DRAFT_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const createDraftAppointmentSaga = (
  payload: CreateDraftAppointmentSagaAction["payload"],
  meta?: CreateDraftAppointmentSagaAction["meta"]
): CreateDraftAppointmentSagaAction => ({
  type: AppointmentActionTypes.CREATE_DRAFT_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const savePublishedAppointmentSaga = (
  payload: SavePublishedAppointmentSagaAction["payload"],
  meta?: SavePublishedAppointmentSagaAction["meta"]
): SavePublishedAppointmentSagaAction => ({
  type: AppointmentActionTypes.SAVE_PUBLISHED_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const cancelAppointmentSaga = (
  payload: CancelAppointmentSagaAction["payload"],
  meta?: CancelAppointmentSagaAction["meta"]
): CancelAppointmentSagaAction => ({
  type: AppointmentActionTypes.CANCEL_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const deleteAppointmentSaga = (
  payload: DeleteAppointmentSagaAction["payload"],
  meta?: DeleteAppointmentSagaAction["meta"]
): DeleteAppointmentSagaAction => ({
  type: AppointmentActionTypes.DELETE_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const assignManualAppointmentSaga = (
  payload: AssignManualAppointmentSagaAction["payload"],
  meta?: AssignManualAppointmentSagaAction["meta"]
): AssignManualAppointmentSagaAction => ({
  type: AppointmentActionTypes.ASSIGN_MANUAL_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const assignRescheduledBeautyCenterAppointmentSaga = (
  payload: AssignRescheduledBeautyCenterAppointmentSagaAction["payload"],
  meta?: AssignRescheduledBeautyCenterAppointmentSagaAction["meta"]
): AssignRescheduledBeautyCenterAppointmentSagaAction => ({
  type: AppointmentActionTypes.ASSIGN_RESCHEDULED_BEAUTY_CENTER_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const assignRescheduledCustomerAppointmentSaga = (
  payload: AssignRescheduledCustomerAppointmentSagaAction["payload"],
  meta?: AssignRescheduledCustomerAppointmentSagaAction["meta"]
): AssignRescheduledCustomerAppointmentSagaAction => ({
  type: AppointmentActionTypes.ASSIGN_RESCHEDULED_CUSTOMER_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const updateAppointmentSaga = (
  payload: UpdateAppointmentSagaAction["payload"],
  meta?: UpdateAppointmentSagaAction["meta"]
): UpdateAppointmentSagaAction => ({
  type: AppointmentActionTypes.UPDATE_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const markAbsenceAppointmentSaga = (
  payload: MarkAbsenceAppointmentSagaAction["payload"],
  meta?: MarkAbsenceAppointmentSagaAction["meta"]
): MarkAbsenceAppointmentSagaAction => ({
  type: AppointmentActionTypes.MARK_ABSENCE_APPOINTMENT_SAGA,
  payload,
  meta,
});
