import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentsPayload,
  FetchAppointmentsResponseData,
  FetchAppointmentPayload,
  FetchAppointmentResponseData,
  CreateDraftAppointmentPayload,
  AssignManualAppointmentPayload,
  AssignRescheduledBeautyCenterAppointmentPayload,
  AssignRescheduledCustomerAppointmentPayload,
  UpdateDraftAppointmentPayload,
  SavePublishedAppointmentPayload,
  CancelAppointmentPayload,
  DeleteAppointmentPayload,
  DuplicateAppointmentPayload,
  DuplicateAppointmentResponse,
  DownloadAppointmentsPayload,
  UpdateAppointmentPayload,
  MarkAbsenceAppointmentPayload,
} from "./appointment.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentApi = {
  fetchAppointments: (payload?: FetchAppointmentsPayload) => {
    return authAxios.get<AxiosResponseData<FetchAppointmentsResponseData>>(
      "appointments",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchAppointment: (payload: FetchAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchAppointmentResponseData>>(
      `appointments/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createDraftAppointment: (payload: CreateDraftAppointmentPayload) => {
    return authAxios.post<AxiosResponseData>(
      "appointments/draft",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  updateAppointment: (payload: UpdateAppointmentPayload) => {
    const { id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(`appointments/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateDraftAppointment: (payload: UpdateDraftAppointmentPayload) => {
    const { draft_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `appointments/draft/${draft_id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  savePublishedAppointment: (payload: SavePublishedAppointmentPayload) => {
    return authAxios.post<AxiosResponseData>(
      "appointments/publish",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  assignManualAppointment: (payload: AssignManualAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/forced_assign/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  assignRescheduledBeautyCenterAppointment: (
    payload: AssignRescheduledBeautyCenterAppointmentPayload
  ) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/reschedule_by_beauty_center/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  assignRescheduledCustomerAppointment: (
    payload: AssignRescheduledCustomerAppointmentPayload
  ) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/reschedule_by_customer/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  cancelAppointment: (payload: CancelAppointmentPayload) => {
    const { id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `appointments/cancel/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteAppointment: (payload: DeleteAppointmentPayload) => {
    const { id } = payload.params;

    return authAxios.delete<AxiosResponseData>(`appointments/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  duplicateAppointment: (payload: DuplicateAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.post<AxiosResponseData<DuplicateAppointmentResponse>>(
      `appointments/copy/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  downloadAppointments: (payload: DownloadAppointmentsPayload) => {
    return authAxios.get<Blob>("appointments/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
  markAbsenceAppointment: (payload: MarkAbsenceAppointmentPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(
      `appointments/absence/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default appointmentApi;
